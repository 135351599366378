import {Link} from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {NavigationContext} from '../services/navigation';
import {devices} from '../styles/breakpoints';
import theme, {menuTransitionDuration} from '../styles/theme';

const mainRoutes = ['films', 'series', 'theater'];
const secondaryRoutes = ['about', 'press', 'contact', 'cinemble'];

const Markonis = styled(Link)`
  font-size: 28px;
  text-transform: uppercase;
  color: ${theme.palette.primary};
  letter-spacing: 5px;
  margin-right: -5px;
  text-decoration: none;
  font-weight: bold;
  @media ${devices.tabletPortrait} {
    font-size: 32px;
    letter-spacing: 6px;
    margin-right: -6px;
  }
  @media ${devices.desktop} {
    font-size: 36px;
  }
`;

const MainRoutesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > a {
    transition: color 200ms;
    letter-spacing: 4px;
    font-size: 17px;
    text-decoration: none;
    margin-right: 16px;
    color: white;
    position: relative;
    @media ${devices.tabletPortrait} {
      font-size: 18px;
    }
    @media ${devices.desktop} {
      font-size: 20px;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 50%;
      transform: scaleX(0.7);
      transform-origin: left;
      height: 2px;
      bottom: -2px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all 300ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary}70;
      &::after {
        opacity: 0.5;
        bottom: -2px;
        left: 0;
        transform: scaleX(1);
      }
    }
    &:focus {
      outline: none;
    }
  }
  & > a:last-child {
    margin-right: -4px;
  }
  @media ${devices.desktop} {
    & > a:last-child {
      margin-right: -5px;
    }
  }
`;

const SecondaryRoutesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
  @media ${devices.tabletPortrait} {
    margin-top: 5px;
  }
  @media ${devices.desktop} {
    margin-top: 6px;
  }
  & > a {
    transition: all 300ms;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0px;
    text-decoration: none;
    margin-right: 16px;
    @media ${devices.tabletPortrait} {
      letter-spacing: 1px;
      font-size: 14px;
    }
    @media ${devices.desktop} {
      letter-spacing: 1px;
      font-size: 15px;
    }
    position: relative;
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      transform: scaleX(0.7);
      transform-origin: left;
      width: 50%;
      height: 2px;
      bottom: 1px;
      left: -15%;
      background-color: ${theme.palette.primary};
      opacity: 0.1;
      transition: all 300ms ease-out;
    }
    &:hover {
      color: ${theme.palette.primary}70;
      &::after {
        opacity: 0.5;
        bottom: 1px;
        left: 0;
        transform: scaleX(1);
      }
      /* color: ${theme.palette.secondary}; */

    }
    &:focus {
      outline: none;
      /* color: ${theme.palette.secondary}; */
    }
  }
  & > a:last-child {
    margin-right: 0px;
  }
  @media ${devices.tabletPortrait} {
    & > a:last-child {
      margin-right: -1px;
    }
  }
`;

const MenuContainer = styled.div`
  margin-top: 36px;
  font-family: 'Quattrocento sans', sans-serif;
  color: white;
  text-transform: uppercase;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Routes = styled.div`
  width: min(320px, 80vw);
  margin-top: 8px;
  @media ${devices.tabletPortrait} {
    margin-top: 10px;
  }
  @media ${devices.desktop} {
    margin-top: 12px;
  }
`;

const Menu: React.FC = () => {
  const {location} = React.useContext(NavigationContext);
  const isHome = location === '/';
  return (
    <MenuContainer>
      <Markonis to='/'>
        Stavros Markonis
      </Markonis>
      <Routes>
        <MainRoutesContainer>
          {mainRoutes.map(mr => (
            <Link
              key={mr}
              to={`/${mr}`}
              partiallyActive={true}
              activeClassName="active-main-link"
              state={{enableTranslationTransition: isHome}}
            >
              {mr}
            </Link>
          ))}
        </MainRoutesContainer>
        <SecondaryRoutesContainer>
          {secondaryRoutes.map(sr => (
            <Link
              key={sr}
              to={`/${sr}`}
              activeClassName="active-secondary-link"
              state={{enableTranslationTransition: isHome}}
            >
              {sr}
            </Link>
          ))}
        </SecondaryRoutesContainer>
      </Routes>
    </MenuContainer>
  )
}

export default Menu;
