import React from 'react';

interface NavigationContextValue {
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  menuTransitionEnded: boolean;
  setMenuTransitionEnded: React.Dispatch<React.SetStateAction<boolean>>;
  visibleBackground?: number;
  setVisibleBackground: React.Dispatch<React.SetStateAction<number | undefined>>;
  showOverlay: boolean;
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundHeight: number | string;
  setBackgroundHeight: React.Dispatch<React.SetStateAction<number | string>>;
}

const defaultContextValue: NavigationContextValue = {
  location: '/',
  setLocation: () => '',
  menuTransitionEnded: false,
  setMenuTransitionEnded: () => {},
  visibleBackground: 0,
  setVisibleBackground: () => {},
  showOverlay: false,
  setShowOverlay: () => {},
  backgroundHeight: 'calc(var(--vh, 1vh) * 100)',
  setBackgroundHeight:  () => {}
};

export const NavigationContext = React.createContext(defaultContextValue);

const NavigationProvider: React.FC = ({children}) => {
  const [location, setLocation] = React.useState<string>('/');
  const [menuTransitionEnded, setMenuTransitionEnded] = React.useState(false)
  const [showOverlay, setShowOverlay] = React.useState(false)
  const [visibleBackground, setVisibleBackground] = React.useState<number | undefined>()
  const [backgroundHeight, setBackgroundHeight] = React.useState<number | string>('calc(var(--vh, 1vh) * 100)')
  return (
    <NavigationContext.Provider value={{
      location,
      setLocation,
      menuTransitionEnded,
      setMenuTransitionEnded,
      visibleBackground,
      setVisibleBackground,
      showOverlay,
      setShowOverlay,
      backgroundHeight,
      setBackgroundHeight
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
