import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';
import {convertToBgImage} from 'gbimage-bridge';
import React from 'react';
import styled from 'styled-components';
import {NavigationContext} from '../services/navigation';
import {menuTransitionDuration} from '../styles/theme';

const BackgroundsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const Background = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
  height: 100vh;
  transition: all ${menuTransitionDuration}ms;
`;

const Backgrounds: React.FC = () => {
  const {setBackgroundHeight, backgroundHeight, visibleBackground, setShowOverlay} = React.useContext(NavigationContext);
  const {bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1000,
            quality: 100,
            blurredOptions: {width: 20}
          )
        }
      }
    }
  `)
  const image = getImage(bisbikis);
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundsContainer>
      <Background
        {...bgImage}
        Tag="section"
        preserveStackingContext
        style={{
          height: backgroundHeight,
        }}
      />
    </BackgroundsContainer>
  )
}

export default Backgrounds;
