type Breakpoints = {
  tabletPortrait: number;
  tabletLandscape: number;
  desktop: number;
  desktopL: number;
  desktopXL: number;
}

export const breakpoints: Breakpoints = {
  tabletPortrait: 600,
  tabletLandscape: 900,
  desktop: 1200,
  desktopL: 1440,
  desktopXL: 1600
};

const mediaQuery = (minWidth?: number, maxWidth?: number) => {
  const hasMin = typeof minWidth !== 'undefined';
  const hasMax = typeof maxWidth !== 'undefined';
  return `${
    hasMin ? `(min-width: ${minWidth}px)` : ''
  }${
    hasMin && hasMax ? ' and ' : ''
  }${
    hasMax ? `(max-width: ${maxWidth}px)` : ''
  }`;
};

export const devices = {
  tabletPortrait: mediaQuery(breakpoints.tabletPortrait),
  tabletLandscape: mediaQuery(breakpoints.tabletLandscape),
  desktop: mediaQuery(breakpoints.desktop),
  desktopL: mediaQuery(breakpoints.desktopL),
  desktopXL: mediaQuery(breakpoints.desktopXL),
  mobileOnly: mediaQuery(undefined, breakpoints.tabletPortrait - 1),
  tabletPortraitOnly: mediaQuery(breakpoints.tabletPortrait, breakpoints.tabletLandscape - 1),
  tabletLandscapeOnly: mediaQuery(breakpoints.tabletLandscape, breakpoints.desktop - 1),
  desktopOnly: mediaQuery(breakpoints.desktop, breakpoints.desktopL - 1),
  desktopLOnly: mediaQuery(breakpoints.desktopL, breakpoints.desktopXL - 1),
};
