// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cinemble-tsx": () => import("./../../../src/pages/cinemble.tsx" /* webpackChunkName: "component---src-pages-cinemble-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-films-tsx": () => import("./../../../src/pages/films.tsx" /* webpackChunkName: "component---src-pages-films-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-series-tsx": () => import("./../../../src/pages/series.tsx" /* webpackChunkName: "component---src-pages-series-tsx" */),
  "component---src-pages-theater-tsx": () => import("./../../../src/pages/theater.tsx" /* webpackChunkName: "component---src-pages-theater-tsx" */),
  "component---src-templates-film-tsx": () => import("./../../../src/templates/film.tsx" /* webpackChunkName: "component---src-templates-film-tsx" */),
  "component---src-templates-series-tsx": () => import("./../../../src/templates/series.tsx" /* webpackChunkName: "component---src-templates-series-tsx" */),
  "component---src-templates-theater-tsx": () => import("./../../../src/templates/theater.tsx" /* webpackChunkName: "component---src-templates-theater-tsx" */)
}

