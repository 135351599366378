import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import {Strapi_ComponentMoleculesSocialLink, Strapi_Contact} from '../../typegen/graphql';
import {devices} from '../styles/breakpoints';
import {StaticImage} from 'gatsby-plugin-image';
import theme from '../styles/theme';
import {NavigationContext} from '../services/navigation';

const renderIcon: (link: Strapi_ComponentMoleculesSocialLink) => any = link => {
  switch(link.platform) {
    case 'facebook': return <StaticImage placeholder="none" src='../images/facebook_white.png' alt="facebook"/>
    case 'imdb': return <StaticImage placeholder="none" src='../images/imdb_white.png' alt="imdb"/>
    case 'instagram': return <StaticImage placeholder="none" src='../images/insta_white.png' alt="insta"/>
    case 'spotify': return <StaticImage placeholder="none" src='../images/spotify_white.png' alt="spotify"/>
    case 'youtube': return <StaticImage placeholder="none" src='../images/youtube_white.png' alt="youtube"/>
  }
};

const LinksContainer = styled.div`
  /* width: 80%; */
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  margin-top: 32px;
  @media ${devices.tabletPortrait} {
    margin-top: 40px;
    gap: 32px;
    /* a32ign-items: center; */
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 80vw;
      height: 2px;
      top: -32px;
      left: calc(50% - 200px);
      background-color: ${theme.palette.primary};
      opacity: 0.2;
    }
  }
  @media ${devices.desktop} {
    margin-top: 48px;
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 80vw;
    width: 300px;
    height: 2px;
    top: -32px;
    left: calc(50% - 150px);
    background-color: ${theme.palette.primary};
    opacity: 0.2;
  }
`;

const IconLink = styled.a`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: inline-block;
  & > div {
    transition: transform 200ms ease-out;
    transform: scale(1);
    opacity: 0.8;
  }
  &:hover {
    & > div {
      transform: scale(1.1);
      opacity: 1;
    }
  }
`;

const Footer: React.FC = () => {
  const {location} = React.useContext(NavigationContext);
  const {strapi} = useStaticQuery(graphql`
    query {
      strapi {
        contact {
          links {
            platform
            url
          }
        }
      }
    }
  `);
  const {links} = strapi.contact as Strapi_Contact;
  return (
    <LinksContainer style={{
      marginTop: location === '/' && 'auto',
      display: location.includes('contact') ? 'none' : 'flex'
    }}
    >
      {(links as Strapi_ComponentMoleculesSocialLink[]).map((link) => (
        <IconLink
          key={link?.id}
          href={link?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderIcon(link)}
        </IconLink>
      ))}
    </LinksContainer>
  )
} 

export default Footer;
